




































  import { PRODUCTS } from '@/constant/Enums';
  import { ServerResponse } from '@/services/response.types';
  import { FormCompareKecelakaanDiriModel, PackageModel, ProductKecelakaanDiriStateModel } from '@/store/product/kecelakaandiri/types';
  import { BSelectOption } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { Action, Mutation, State } from 'vuex-class';

  @Component({
    name: 'comparePersonalAccident',
    components: {},
    mixins: [],
  })
  export default class comparePersonalAccident extends Vue {
    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) desciption!: string;
    @Prop({ default: 'BANDINGKAN SEKARANG' }) buttonTitle!: string;

    @Action('GetKecelakaanDiri', { namespace: 'product/kecelakaandiri' }) GetKecelakaanDiri!: () => Promise<ServerResponse>;
    @Action('CompareAction', { namespace: 'product/kecelakaandiri' }) CompareAction!: (form: FormData) => Promise<ServerResponse>;

    @State('package', { namespace: 'product/kecelakaandiri' }) package!: PackageModel[];
    @State('form', { namespace: 'product/kecelakaandiri' }) formKecelakaanDiri!: FormCompareKecelakaanDiriModel;
    @State((state: ProductKecelakaanDiriStateModel) => state.title?.HTMLtoString(), { namespace: 'product/kecelakaandiri' }) _title!: string;
    @State((state: ProductKecelakaanDiriStateModel) => state.content?.HTMLtoString(), { namespace: 'product/kecelakaandiri' }) content!: string;

    @Mutation('set-form', { namespace: 'product/kecelakaandiri' }) SetForm!: (form: any) => void;

    private routeName = 'compare-' + PRODUCTS[3].name.toLowerCase().replace(' ', '');

    public get form(): FormCompareKecelakaanDiriModel {
      return this.formKecelakaanDiri;
    }

    public set form(v: FormCompareKecelakaanDiriModel) {
      this.SetForm(v);
    }

    public get titleMutator(): string {
      return this.title ?? this._title;
    }

    public get descriptionMutator(): string {
      return this.desciption ?? this.content;
    }

    public typeOptions: BSelectOption[] = [
      {
        text: 'Hemat',
        value: 1,
      },
      {
        text: 'Reguler',
        value: 1,
      },
    ];
    public get packageOptions(): BSelectOption[] {
      return this.package.map((item) => {
        return {
          text: item.package_name_ind,
          value: item.pa_package_id,
        };
      });
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      this.GetKecelakaanDiri().then(() => {
        let history = localStorage.getItem('kecelakaandiri-history');
        if (typeof history === 'string') {
          this.form = JSON.parse(history);
          if (this.$route.name === this.routeName) this.OnSubmit({ target: this.$refs['compareKecelakaanDiri'] } as any);
        }
      });
    }
    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      localStorage.setItem('kecelakaandiri-history', JSON.stringify(this.formKecelakaanDiri));
      this.CompareAction(new FormData(ev.target as HTMLFormElement)).then((res) => {
        if (this.$route.name !== this.routeName) this.$router.push({ name: this.routeName });
      });
    }
  }
